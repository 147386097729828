import React from 'react';
import BackgroundTrim from './background-trim';

import './site-footer.scss';

export default function SiteFooter() {
  return (
    <footer className="site-footer">
      <BackgroundTrim position="top" />
    </footer>
  );
}
