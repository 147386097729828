import React from 'react';

import './nav-bullet-svg.scss';

export default function NavBulletSvg() {
  return (
    <svg
      aria-hidden="true"
      className="nav-bullet-svg"
      viewBox="0 0 1 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" x="0" y="0" width="1" height="1" />
    </svg>
  );
}
